@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.guide-card {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0.75rem 0;
  cursor: pointer;

  .guide-card:first-child() {
    margin-top: 0;
  }

  @include tablet {
    height: 20rem;
    border: solid 1px #cdcccc;

    margin-right: 1rem;
    max-width: 26rem;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  }

  &--category {
    border: solid 1px blue;
    width: 100%;

    @include tablet {
      width: 100%;
    }
  }

  &:hover {
    // background-color: #e6edf3;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    border-bottom: solid 1px rgba(0, 0, 0, 0.165);
    border-top: solid 1px rgba(0, 0, 0, 0.165);

    @include tablet {
      background-color: #e6edf3;
    }
  }

  &__author {
    color: #2993e7;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0.25rem 0 0 0.25rem;
    padding-left: 0.25rem;
  }

  &__details {
    display: flex;
    width: 100%;
    // border: solid 1px red;
  }

  &__info {
    width: 100%;
  }

  &__bin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    // border: solid 1px blue;

    @include tablet {
    }
  }

  &__bookmark {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0.25rem;
    // border: solid 1px orange;
    @include tablet {
      height: 40px;
      width: 40px;
      margin: 0.5rem;
    }
  }

  &__send {
    height: 40px;
    width: 40px;
    margin: 0.5rem;
    cursor: pointer;
  }
  &__title {
    @include guide-title;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0.25rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 0.25rem;
    width: 15rem;

    @include tablet {
      font-size: 1.25rem;
      max-width: 20rem;
    }
  }

  &__howto {
    font-size: 0.75rem;
    font-weight: 00;
    opacity: 0.5;
    margin: 0.25rem;
    padding-left: 0.25rem;
  }

  &__poster {
    object-fit: cover;
    width: 100%;
    height: 14rem;

    @include tablet {
      height: 14rem;
      // min-width: 24rem;
      padding-bottom: 0.35rem;
      // border: solid 1px red;
    }
  }
  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    width: 100%;

    @include tablet {
      max-width: 20rem;
    }
  }
}
