@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.signup {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  max-width: 25rem;
  margin: 0;
  font-family: "Outfit";

  &__title {
    font-size: 1.9rem;
  }

  &__section {
    display: flex;
    flex-direction: column;
    // border: solid 1px red;
    margin-bottom: 1rem;
  }

  &__label {
    font-size: 0.9rem;
    color: $gg-blue;
  }

  &__input {
    box-sizing: border-box;
    padding-left: 1rem;
    border: 2px solid $gg-blue-light;
    border-radius: 50px;
    height: 1.7rem;
    background-color: white;

    &--error {
      border: solid 2px red;
    }

    &:focus {
      transition: 0.5s;
      border: 2px solid $gg-blue;
      background-color: white;
    }
  }

  &__button {
    box-sizing: border-box;
    padding-left: 1rem;
    border: 2px solid $gg-blue-light;
    // background-color: $gg-blue-light;
    color: black;
    border-radius: 50px;
    height: 2.5rem;
    width: 7rem;

    &:hover {
      background-color: $gg-blue;
      color: white;
    }
  }
}
