@use "./colors" as *;

$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

// mobile padding
$mobile-outer-padding: 1rem;
$mobile-inner-padding: 1.5rem;
$mobile-totalHorizontal-padding: $mobile-outer-padding + $mobile-inner-padding;
// tablet padding
$tablet-outer-padding: 2rem;
$tablet-inner-padding: 2rem;
$tablet-totalHorizontal-padding: $tablet-outer-padding + $tablet-inner-padding;
// desktop padding
$desktop-outer-padding: 8.125rem;
$desktop-inner-padding: 2.5rem;
$desktop-totalHorizontal-padding: $desktop-outer-padding +
  $desktop-inner-padding;

//mobile vertical padding
$mobile-vertical-outer-padding: 1rem;
$mobile-vertical-inner-padding: 1.5rem;
