@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.cat-page {
  @include body-section;
  @include padding-horizontal-outer;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include tablet {
  }

  &__rows {
    display: flex;
  }

  &__title {
    text-align: center;
    margin: 1rem 0;
    color: $gg-blue;
    font-size: 3rem;
  }

  &__guide {
    margin: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    // border: solid 1px red;
  }
}
