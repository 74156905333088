@use "variables" as *;
@use "colors" as *;
@use "typography" as *;

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

// Mobile, tablet and desktop padding (outer) horizontal
@mixin padding-horizontal-outer {
  padding-left: $mobile-outer-padding;
  padding-right: $mobile-outer-padding;

  @include tablet {
    padding-left: $tablet-outer-padding;
    padding-right: $tablet-outer-padding;
  }

  @include desktop {
    padding-left: $desktop-outer-padding;
    padding-right: $desktop-outer-padding;
  }
}

// Mobile, tablet and desktop padding (inner) horizontal
@mixin padding-horizontal-inner {
  padding-left: $mobile-inner-padding;
  padding-right: $mobile-inner-padding;

  @include tablet {
    padding-left: $tablet-inner-padding;
    padding-right: $tablet-inner-padding;
  }

  @include desktop {
    padding-left: $desktop-inner-padding;
    padding-right: $desktop-inner-padding;
  }
}

// Padding vertical mobile only
@mixin padding-vertical {
  padding-top: $mobile-vertical-inner-padding;
  padding-bottom: $mobile-vertical-outer-padding;
}

// Search form styling for mobile
@mixin form {
  height: 36px;
  width: 100%;
  border-radius: 30px;
  outline: none;
  border: 1px solid $gg-grey;
}

@mixin nav-styling {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  box-sizing: border-box;
  padding-top: 2rem;
  width: 100%;
  z-index: 1;
  background-color: $gg-black;

  @include tablet {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin body-section {
  background-color: $gg-cloud;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

@mixin categories-styling {
  @include tablet {
    margin: 0;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    display: flex;
  }
}

@mixin categories-section-styling {
  @include tablet {
    display: flex;
    flex-direction: row;
    max-width: 40rem;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: space-evenly;
    box-sizing: border-box;
  }
}

@mixin search-styling {
  padding-top: 12rem;

  @include tablet {
    margin: 0;
    justify-content: center;
    align-items: center;
    // box-sizing: border-box;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 8rem;
    display: flex;
  }
}

@mixin guide-card {
  // width: 92%;
  display: flex;
  background-color: $gg-blue-light;
  border: 2px solid $gg-blue;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

@mixin guide-poster {
  background-color: $gg-white;
  margin-bottom: 1rem;
}

@mixin category-poster {
  width: 100%;
}
@mixin category-card {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  // background-color: $gg-cloud;
}

@mixin guide-title {
  color: black;
  font-size: 1.5rem;
  // text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
  text-decoration: none;
}

@mixin button-styling {
  background-color: $gg-white;
  color: $gg-black;
  // border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 2rem;
  // border: 2px solid $gg-black;
  // box-sizing: border-box;
  font-size: 0.9rem;
  &:hover {
    background-color: $gg-black;
    color: $gg-white;
  }
}

@mixin button-animation {
  color: white;

  &::after {
    display: inline-block;
    padding-left: 8px;
    content: "\0279E"; // arrow right unicode
    -webkit-transition: transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out;
    -ms-transition: transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }

  &:hover {
    color: white;
    // background-color: #f6f3ed;

    &::after {
      -webkit-transform: translateX(4px);
      -moz-transform: translateX(4px);
      -ms-transform: translateX(4px);
      -o-transform: translateX(4px);
      transform: translateX(4px);
    }
  }
}

@mixin completed-step {
  background-color: $gg-green-light;
  border: $gg-green 2px solid;
}

@mixin search-button {
  cursor: pointer;
  color: white;
  background-color: $gg-blue;
  border: solid 3px $gg-blue;
  height: 36px;
  padding: 0rem 0.5rem;
  &:hover {
    background-color: $gg-blue-dark;
    border: solid 3px $gg-blue-dark;
  }
}
