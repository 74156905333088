@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.cat-row {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin: 0;
  background-color: #e6edf3;

  @include tablet {
    background-color: white;
    margin: 2rem auto;
    max-width: 70rem;
  }

  &__scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // overflow-x: scroll;
    padding-top: 0;
    position: relative;
    width: 100%;

    @include tablet {
      flex-direction: row;
      width: 95%;
    }
  }

  &--title {
    display: none;

    @include tablet {
      display: flex;
      box-sizing: border-box;
      margin: 1rem 1rem 1rem;
      text-decoration: none;
      font-family: $font-stack;
      color: #2993e7;
      font-family: "Outfit";
      font-weight: 700;
      width: 100%;
    }

    @include desktop {
    }
  }

  &__back {
    display: none;

    @include tablet {
      position: absolute;
      display: flex;
    }
  }
  &__next {
    display: none;

    @include tablet {
      display: flex;
      position: absolute;
      right: 0;
    }
  }
}
