@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.guide {
  position: fixed;
  width: 100%;
  height: 100%;
  margin-top: 105px;
  overflow-y: scroll;
  flex-grow: 1;
  top: 0;
  right: 0;
  // border-left: 3px solid $gg-blue;
  background-color: rgb(238, 238, 238);
  animation: slide-mobile 0.2s;
  z-index: 2;
  @include tablet {
    width: 40rem;
    margin-top: 0;
    position: static;
    // box-shadow: 0px 0px 10px #232931;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    animation: slide 0.4s;
  }

  &__intro {
    border: 2px solid #2993e7;
    background-color: #dff1ff;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__ingredients {
    margin: 1rem 0;
  }

  &__list {
    margin: 1rem 0;
  }

  &__item {
    list-style-type: none;
  }

  &__bin {
    margin: 1rem 0;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    border: solid 1px orange;
    justify-content: space-around;
    margin: 1rem 0;
  }

  &__details {
    // margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
  }

  &__title {
    font-size: 1.5rem;
    margin-bottom: 0;
    padding-top: 1rem;
  }

  &__info {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  &__credentials {
    display: flex;
    padding-bottom: 0.5rem;
  }
  &__verified {
  }
  &__author {
    margin-left: 0.25rem;
    font-family: "Outfit Light";
  }

  &__stats {
    display: flex;
    // background-color: $gg_cloud;
  }

  &__heart {
  }

  &__likes {
    margin-left: 0.25rem;
    font-family: "Outfit Light";
  }

  &__social {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    justify-content: center;
    width: 25rem;
  }

  &__cta {
    font-family: "Outfit";
    font-size: 1.3rem;
    color: $gg-grey;
  }

  &__share {
    display: flex;
    width: 12rem;
    justify-content: space-around;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
      width: 100%;
      // align-items: flex-start;
    }
  }

  &__scroll {
    height: 2rem;
    width: 6rem;
  }

  &__close {
    position: fixed;
    top: 125px;
    right: 2rem;
    z-index: 5;
    font-size: 2rem;
    cursor: pointer;

    &:hover {
      color: red;
    }

    @include tablet {
      top: 140px;
      right: 2rem;
    }
  }
}

@keyframes slide-mobile {
  from {
    top: 100vh;
  }
  to {
    top: 0;
  }
}

@keyframes slide {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
