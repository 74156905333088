@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.homepage {
  display: flex;
  height: 100vh;
  background-color: white;
  // overflow-y: scroll;
  &__main {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;

    @include tablet {
      background-color: white;
      padding-bottom: 1rem;
      padding-top: 2.4rem;
      border-bottom: solid 3px #2993e7;
    }
  }

  &__searchbar {
    box-sizing: border-box;
    height: 36px;
    width: 18rem;
    border: solid 2px #2993e7;
    padding-left: 1rem;

    @include tablet {
      margin-bottom: 1rem;
      width: 26rem;
      border: solid 3px #2993e7;
    }

    &:hover {
      border: 3px solid $gg-black;
    }
  }

  &__button {
    @include search-button;
  }

  &__categories {
    width: 100%;
    display: flex;
    box-sizing: border-box;
  }

  &__guides {
    // overflow-y: scroll;
    box-sizing: border-box;
    flex-grow: 2;
    flex-direction: column;
    align-items: center;
    background-color: #e6edf3;

    @include tablet {
      background-color: white;
    }
  }

  &__container {
    // border: solid 1px red;
  }

  &__section {
    max-width: 86rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: space-evenly;
    box-sizing: border-box;
    align-items: center;
    // padding: 1rem;
    // background-color: rgb(238, 238, 238);

    @include tablet {
      // width: 100%;
      flex-direction: row;
      // width: 80rem;
    }
  }
  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    // width: 100%;

    @include tablet {
      max-width: 24rem;
    }
  }

  &__stepcount {
    color: #2993e7;
    font-size: 0.9rem;
    font-weight: 700;
    font-size: 0.7rem;
  }

  &__cardcat {
    font-size: 0.75rem;
    opacity: 0.5;
    padding-bottom: 1rem;
    margin: 0;
    position: absolute;
    top: 10rem;
    left: 16rem;
  }
}
