@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.step-card {
  display: flex;
  background-color: $gg-blue-light;
  border: 2px solid $gg-blue;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;

  &--completed {
    @include completed-step;
  }

  @include tablet {
    padding: 2rem 0;
    max-width: 80%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    //   width: 40rem;
  }

  &--wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;

    @include tablet {
      align-items: center;
      width: 100%;
    }
  }

  &__number {
    font-family: "Outfit";
    font-weight: 900;
    margin: 0;
    color: $gg-grey;
    padding-top: 1rem;
  }

  &__gif {
    width: 90%;
    // @include guide-poster;
    // margin-bottom: 2rem;
    // width: 90%;
    // max-height: 18rem;
    margin: 1rem 1rem;

    @include tablet {
      width: 50%;
      order: 2;
    }
  }

  &__steps {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    flex-direction: column;

    @include tablet {
      padding-right: 1rem;
    }
  }

  &__step {
    font-family: "Outfit";
    font-weight: 400;
    margin: 0;
    max-width: 18rem;
    margin: 1rem 0;

    @include tablet {
      margin-left: 1rem;
    }
  }

  &__checkmark {
    cursor: pointer;
    position: absolute;
    color: $gg-blue;
    background-color: white;
    border: $gg-blue 2px solid;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -25px;
    width: 50px;
    height: 50px;
    border-radius: 25px;

    &:hover {
      border: $gg-blue-dark 2px solid;
      background-color: $gg-blue;
      color: white;
    }

    &--completed {
      @include completed-step;
      &:hover {
        border: $gg-green 2px solid;
        background-color: $gg-green;
        color: white;
      }
    }
  }
}
