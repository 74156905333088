@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.categories {
  @include body-section;
  @include padding-horizontal-outer;
  @include categories-styling;
  padding: 0;
  padding-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include tablet {
    padding-top: 0;
  }

  &__section {
    display: flex;
    flex-direction: row;
    max-width: 40rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include tablet {
      padding: 0;
      justify-content: center;
    }
  }

  &__link {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
  }

  &__card {
    position: relative;
    width: 4rem;
    height: 4rem;
    margin-top: 1rem;

    @include tablet {
    }
  }

  &__title {
    @include guide-title;
    font-size: 0.3rem;
  }

  &__poster {
    @include category-poster;

    @include tablet {
      width: 100%;
    }
  }
}
