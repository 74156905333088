@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.login {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  padding: 1rem;
  font-family: "Outfit";
  width: 100%;
  margin: auto;

  @include tablet {
    margin-top: 1rem;
    width: 30rem;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  }

  &__title {
    font-size: 1.9rem;
    text-align: center;
  }

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  &__label {
    font-size: 0.9rem;
    color: $gg-blue;
  }

  &__input {
    box-sizing: border-box;
    padding-left: 1rem;
    border: none;
    height: 1.7rem;

    &--error {
      border: 2px solid red;
    }

    &:focus {
      transition: 0.5s;
      border: 1px solid $gg-blue;
      // background-color: white;
    }
  }

  &__text {
    font-size: 1rem;
    padding-top: 0.5rem;
    margin-bottom: 0;

    @include tablet {
      font-size: 1.25rem;
    }
  }

  &__button {
    box-sizing: border-box;
    border: 2px solid $gg-blue-light;
    // background-color: $gg-blue-light;
    color: black;
    border-radius: 50px;
    height: 2.5rem;
    width: 7rem;

    &:hover {
      border: none;
      background-color: $gg-blue;
      color: white;
    }
  }
}
