@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.upload {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-left: 5rem;
  padding-right: 5rem;

  &__section {
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 100vh;
    background-color: #f6f6f6;
    // border: solid 1px red;
  }

  &__cards {
    width: 100%;
    background-color: #2993e7;
    color: white;
    @include tablet {
    }
  }

  &__card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #2993e7;
    width: 40rem;
    height: 30rem;
    // border: solid 1px red;
    margin: 1rem;

    @include tablet {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    &--info {
      width: 25rem;
    }

    &--steps {
      width: 30rem;
      justify-content: space-between;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }

  &__steps {
    display: flex;
    align-items: center;
  }

  &__step {
    display: flex;
    border-radius: 50px;
    justify-content: center;
    width: 1rem;
    display: flex;
    color: rgb(228, 228, 228);
    margin: 0.5rem;
    cursor: pointer;

    &:hover {
      color: white;
    }

    &--active {
      color: white;
      background-color: none;
      border: solid 1px white;
      border-radius: 50px;
    }
  }

  &__addstep {
    margin-left: auto;
    border: solid 1px white;
    border-radius: 50px;
    height: 1.5rem;
    font-size: 0.8rem;
    width: 5rem;
    text-align: center;
    padding-top: 0.5rem;
    cursor: pointer;
  }

  &__dropzone {
    // border: solid 1px red;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: white;
    font-family: "Outfit";
    font-size: 1.2rem;
  }

  &__gif {
    max-width: 40rem;
    max-height: 15rem;
    object-fit: cover;
  }

  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }

  &__label {
    margin-bottom: 0.25rem;
    font-family: "Outfit";
    color: white;
    font-size: 0.9rem;
  }

  &__input {
    @include form;
    padding-left: 1rem;
    word-wrap: break-word;
    width: 20rem;
    border: none;

    &--step {
      margin: 1rem;
    }

    &--large {
      height: 4rem;
    }

    &--category {
      width: 10rem;
    }
  }

  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    @include button-styling;
    font-family: "Outfit";
    font-weight: 700;
    width: 7rem;
    margin-right: 1rem;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }

  &__steptext {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    // border: solid 1px red;
  }

  &__submit {
    @include button-styling;
    font-family: "Outfit";
    font-weight: 700;

    &:disabled {
      background-color: red;
      cursor: not-allowed;
    }
  }

  &__submit {
    margin: 1rem;
    margin-left: auto;
    border: none;
    background-color: white;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    cursor: pointer;
  }
}
