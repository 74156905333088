@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.guide-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0.25rem 0.25rem 0.25rem 0;
  font-size: 0.8rem;
  // border: solid 1px red;

  &__likes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem 0 0 0;
  }

  &__like {
    width: 1.25rem;
    margin: 0.25rem;

    &:hover {
      // width: 1.5rem;
    }
  }
  &__unlike {
    width: 1.25rem;
    margin: 0.25rem;

    // &:hover {
    //   width: 1.5rem;
    // }
  }
  &__bold {
    font-weight: 900;
  }
  &__comments {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    color: #808080;
    // border: solid 1px blue;
  }
  &__icon {
    width: 1.15rem;
    margin-right: 0.5rem;
  }
  &__share {
    margin: 0 0.25rem;
    color: #808080;
    display: flex;
    // border: solid 1px green;
  }
  &__arrow {
    width: 1rem;
    margin-right: 0.25rem;
  }
  &__more {
    margin-left: 1rem;
  }
  &__dots {
  }
}
