@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;

.nav {
  @include padding-horizontal-outer;
  // @include padding-vertical;
  @include nav-styling;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  background-color: #2993e7;
  z-index: 3;

  @include tablet {
    padding-left: 2rem;
  }

  &__logo {
    display: flex;
    text-align: left;
    text-decoration: none;
    width: 20%;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }

  &__searchbar {
    width: 20rem;
    height: 2rem;
    border-radius: 15px;
    // border: 2px solid #2993e7;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    padding-left: 1rem;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-family: "Outfit Bold";
    color: #2993e7;
    transition: 1s;

    &:focus {
      outline: none;
      width: 15rem;
    }

    @include tablet {
      &:focus {
        outline: none;
        width: 20rem;
      }
    }
  }

  &__form {
    width: 60%;
    display: flex;
    justify-content: center;

    @include tablet {
      margin-left: 4rem;
    }
  }

  &__categories {
    width: 6rem;
    height: 2rem;
    text-align: center;
    border-radius: 15px;
    font-family: "Outfit Bold";
    color: #2993e7;
    // border: 2px solid #2993e7;
    border: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 2px solid #2993e7;
    // box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    padding-left: 0.5rem;

    &:focus {
      outline: none;
    }

    @include tablet {
      width: 10rem;
    }
  }

  &__actions {
    display: none;

    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 7rem;
      margin-right: 1rem;
      width: 10rem;
    }
  }

  &__logout {
    color: white;
    font-family: "Outfit";
    font-weight: 600;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
      color: rgb(235, 235, 235);
    }
  }

  &__item {
    color: white;
    font-family: "Outfit";
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px white;
    transition: 0.5s;
    @include button-animation;

    &:hover {
      color: rgb(235, 235, 235);
    }

    @include tablet {
    }

    &--logout {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }
  }

  &__titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include tablet {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__title {
    font-family: "Outfit Extra Bold";
    padding-top: 0.25rem;
    color: $gg-white;
    margin: 0;
    line-height: 100%;
    width: 1rem;
    font-size: 1.15rem;

    @include tablet {
      width: 8rem;
      font-size: 2rem;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__actions--mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    // border: solid 1px red;

    @include tablet {
      display: none;
    }
  }
  &__hamburger {
    margin-left: 1rem;

    background-color: transparent;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    border-radius: 15px;
    padding: 0.3rem;

    @include tablet {
      display: none;
    }
  }
}
