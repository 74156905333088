@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.ais-Hits {
  &-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    list-style-type: none;
  }
  &-item {
    margin-bottom: 2rem;
  }
}

.ais-SearchBox-input {
  box-sizing: border-box;
  height: 36px;
  width: 18rem;
  border: solid 2px black;
  padding-left: 1rem;
  margin-bottom: 1rem;

  @include tablet {
    width: 26rem;
    border: solid 3px #2993e7;
  }

  &:hover {
    border: 3px solid $gg-black;
  }
}

.ais-SearchBox-submit {
  @include search-button;
}

.search-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
