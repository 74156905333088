@font-face {
    font-family: 'Heebo';
    src: url('../../assets/fonts/Heebo-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Heebo';
    src: url('../../assets/fonts/Heebo-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Heebo';
    src: url('../../assets/fonts/Heebo-Bold.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Outfit Extra Bold';
    src: url('../../assets/fonts/Outfit-ExtraBold.ttf');
    font-weight: 900;
}

@font-face {
    font-family: 'Outfit Bold';
    src: url('../../assets/fonts/Outfit-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Outfit Regular';
    src: url('../../assets/fonts/Outfit-Regular.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Outfit Light';
    src: url('../../assets/fonts/Outfit-Light.ttf');
    font-weight: 200;
}


$font-stack: 'Heebo', 'sans-serif';
$root-font-size: 100%;