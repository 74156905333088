// Primary black
$gg-black: #010515;

$gg-grey: #343539;

// Primary white
$gg-white: #ffffff;

// light background
$gg-cloud: #f4ece2;

// GIF guide pink
$gg-pink: #f72585;

//GIF guide purple
$gg-purple: #7209b7;

// GIF Guide blue:
$gg-blue-dark: #1185e4;

$gg-blue: #2993e7;

$gg-blue-light: #dff1ff;

$gg-green: #37d128;

$gg-green-light: #c4e3c1;
